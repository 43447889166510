<template>
    <div>
        <!--搜索框-->
        <div style="padding-top: 30px;display: flex;justify-content: flex-start">
            <div>
                请输入账号：
                <el-input v-model="admin.adminName" placeholder="请输入账号" clearable></el-input>
            </div>

            <div style="padding-left: 30px">
                <el-button size="small" type="primary" @click="findPage">搜索</el-button>
            </div>

            <div style="padding-left: 30%">
                <el-button size="small" type="primary" @click="showAdd">添加账户</el-button>
            </div>
        </div>

        <!--内容-->
        <div style="padding-top: 50px" v-loading="loading">
            <el-table
                    :data="list"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="registerMail"
                        label="账号"
                        width="200"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="realName"
                        width="200"
                        label="昵称"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="totalIncomeNum"
                        width="200"
                        label="总收入"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="invitCode"
                        width="200"
                        label="邀请码"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="isStatus"
                        width="150"
                        label="状态"
                        align="center">
                    <template slot-scope="scope">
                        <el-tag effect="dark" :type="scope.row.userStatus==1?'':'danger'">
                            {{scope.row.userStatus==1?'正常':'封禁'}}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="createTime"
                        width="200"
                        label="创建时间"
                        align="center">
                </el-table-column>

                <el-table-column
                        align="center"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="chakan(scope.row.id)">查看会员</el-button>
<!--                        <el-button type="text" @click="liuliang(scope.row.id)">流量记录</el-button>-->
                        <el-button type="text" @click="changeStatus(scope.row.id)">
                            {{scope.row.userStatus==1?'封禁':'取消封禁'}}
                        </el-button>
                        <el-button type="text" @click="deleteById(scope.row.id)">删除</el-button>
                        <el-button type="text" @click="resetPass(scope.row.id)">重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <div style="padding-top: 50px;text-align: center">
            <el-pagination
                    class="pagiantion"
                    :total="total"
                    @current-change="handleSelectionChange"
                    :current-page="admin.pageIndex"
                    :page-size="admin.pageSize"
                    layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>


        <el-dialog
                title="添加账户"
                :visible.sync="centerDialogVisible2"
                width="25%"
                :before-close="beforeClose"
                center>
            <div>
                <div>
                    登陆账户：
                    <el-input v-model="addAdmin.adminName" clearable></el-input>
                </div>

                <div style="padding-top: 20px">
                    用户昵称：
                    <el-input v-model="addAdmin.realName" clearable></el-input>
                </div>

                <div style="padding-top: 20px">
                    登陆密码：
                    <el-input v-model="addAdmin.password" type="password" clearable></el-input>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="beforeClose">取 消</el-button>
    <el-button type="primary" @click="submitAdd">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "AccountList",
        data() {
            return {
                list: [],
                loading: false,
                admin: {
                    pageIndex: 1,
                    pageSize: 5,
                    adminName: ''
                },
                total: 0,
                centerDialogVisible: false,
                centerDialogVisible2: false,
                perList: [],   //权限集合
                choose: [],  //选中的权限id
                id: null,
                addAdmin: {},
                pList:[]
            }
        },
        methods: {
            resetPass(id){
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/user/resetPass?id=' + id).then(resp => {
                        if (resp.data.code == 200) {
                            this.$message.success("已将登录密码和支付密码重置为123456")
                            this.findPage();
                        }
                    })
                })
            },
            liuliang(id){
                this.$router.replace('/liuLiangRecord?id='+id)
            },
            chakan(id){
                this.$router.replace('/accountDetail?id='+id)
            },
            submitAdd() {
                if (!this.addAdmin.adminName || this.addAdmin.adminName.trim() == '') {
                    this.$message.warning('登陆账户不能为空')
                    return false;
                }

                if (!this.addAdmin.realName || this.addAdmin.realName.trim() == '') {
                    this.$message.warning('真实姓名不能为空')
                    return false;
                }

                if (!this.addAdmin.password || this.addAdmin.password.trim() == '') {
                    this.$message.warning('登陆密码不能为空')
                    return false;
                }

                this.axios.post('/user/addGrent', this.addAdmin).then(resp => {
                    if (resp.data.code == 200) {
                        this.addAdmin={}
                        this.centerDialogVisible2 = false
                        this.$message.success(resp.data.message)
                        this.findPage();
                    }
                })
            },
            beforeClose() {
                this.addAdmin = {}
                this.pList=[]
                this.centerDialogVisible2 = false
            },
            showAdd() {
                this.centerDialogVisible2 = true;
            },
            deleteById(id) {
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/user/deleteById?id=' + id).then(resp => {
                        if (resp.data.code == 200) {
                            this.$message.success(resp.data.message)
                            this.findPage();
                        }
                    })
                })
            },
            changeStatus(id) {
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/user/lahei?id=' + id).then(resp => {
                        if (resp.data.code == 200) {
                            this.$message.success(resp.data.message)
                            this.findPage();
                        }
                    })
                })
            },
            submitEdit() {
                let obj = {
                    id: this.id,
                    menuIds: this.choose
                }
                this.axios.post('/admin/permissionsEdit', obj).then(resp => {
                    if (resp.data.code == 200) {
                        this.centerDialogVisible = false
                        this.$message.success(resp.data.message)
                        this.findPage();
                    }
                })
            },
            showEdit(id, menuIds) {
                this.id = id
                this.centerDialogVisible = true
                this.choose = menuIds;
            },
            findPage() {
                this.loading = true;
                this.axios.post('/user/findAgentPage', this.admin).then(resp => {
                    if (resp.data.code == 200) {
                        this.list = resp.data.data.data;
                        this.total = resp.data.data.total;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleSelectionChange(currentPage) {
                this.admin.pageIndex = currentPage;
                this.findPage();
            },
        },
        created() {
            this.findPage()
        }
    }
</script>

<style scoped>
    .el-input {
        width: 300px;
    }
</style>
